import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import TimeKeeper from 'react-timekeeper';
import './overrides.css';

import Icon from 'components/shared/icon';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

export default ({ onSelectItem, onVerify = () => true, placeholder }) => {
    useStyles(classes);
    const { t } = useTranslation('timePicker');

    const [time, setTime] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [showTime, setShowTime] = useState(false)

    const handleChange = time => {
        const value = time ? time.formatted24 : null;

        onSelectItem(value);
        setSelectedTime(value);
        setTime(time);
    };

    useEffect(() => {
        if (!onVerify(time)) {
            setSelectedTime(null);
            onSelectItem(null);
        }
    }, [time]);

    return (
        <div className={classes.container}>
            {
                showTime &&
                <div className={classes.root}>
                    <TimeKeeper
                        time={selectedTime}
                        onChange={handleChange}
                        switchToMinuteOnHourSelect
                        hour24Mode
                        coarseMinutes={5}
                        forceCoarseMinutes
                        closeOnMinuteSelect
                        doneButton={() => (
                            <div
                                className={classes.cancelButton}
                                onClick={() => setShowTime(false)}
                            >
                                { t('accept')}
                            </div>
                        )}
                    />
                </div>

            }
            <input
                value={selectedTime || ''}
                className={classes.input}
                placeholder={placeholder}
                onClick={() => setShowTime(!showTime)}
                readOnly
            />
            <div className={classes.iconContainer} onClick={() => handleChange(null)}>
                <Icon classes={classes.icon} name='times-circle' />
            </div>
        </div>
    )
}
