// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._939b86ec982253eac4a7d5242b9cf1cd-scss{background:var(--white-light);padding:10px;border-radius:6px;width:100%;height:80px;max-height:80px;resize:none;font-size:1rem;border:1px solid var(--grey-light)}\n", ""]);
// Exports
exports.locals = {
	"input": "_939b86ec982253eac4a7d5242b9cf1cd-scss"
};
module.exports = exports;
