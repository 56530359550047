import React from 'react';

import Autocomplete from 'components/shared/autocomplete';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ usesElastic, onSelectItem, stops = [], initValue, placeholder, err, id, ...rest }) => {
    useStyles(classes);
    const handlerSelectedItem = item => onSelectItem(item, id)

    return (
        <div>
            <Autocomplete 
                onSelectItem={handlerSelectedItem} 
                source={stops} 
                initValue={initValue}
                inputOptions={{ 'arial-label': placeholder, placeholder }}
                usesElastic={usesElastic}
                { ...rest }
            />
            {
                err && err.status &&
                <div className={classes.helperText}>
                    { err.message }
                </div>
            }
        </div>
    )
}