const mapData = data => {
    if(data.length === 0) return [];
    
    return data.map(item => ({
        text: item.description,
        id: item.place_id
    }))
}

const mapItem = (result, item) => ({
    text: item.text,
    id: item.id,
    latitude: result.geometry.location.lat(),
    longitude: result.geometry.location.lng()
})

export {
    mapData,
    mapItem
}