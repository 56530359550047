import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import Icon from 'components/shared/icon';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { useApplication } from 'hooks/application';

import { removeAccents } from 'shared/common.js';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

import axios from 'axios';

const Autocomplete = ({ usesElastic, onSelectItem, initValue, inputOptions, source, characters, hasHeaders, onBlurSelected, onKeyDown }) => {
  useStyles(classes);
  const { features,country } = useApplication();

  const [suggestions, setSuggestions] = useState([]);
  const [highlightedSuggestion, setHighlightedSuggestion] = useState({ text: '' });
  const [suggestionsDisplayed, setSuggestionsDisplayed] = useState(false);
  const [selected, setSelected] = useState('');
  const [currentDeparture, setCurrentDeparture] = useState('')

  useEffect(() => {
    if (initValue) setSelected(initValue.text);
  }, [initValue]);

  useEffect(() => {
    setSuggestions(source);
  }, [source])

  useEffect(()=>{
    if (currentDeparture.length> 0){
      const interval = setTimeout(()=> {getSuggestions(currentDeparture)},500)
      return ()=> clearTimeout(interval)
    }
  },[currentDeparture])

  const getSuggestionValue = suggestion => {
    setSuggestionsDisplayed(false);
    onSelectItem(suggestion);
    return suggestion.text;
  }

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    if (suggestion.isAgrupador === 1) {
      return (
        <div className={classes.title}>
          <div className={classes.icon}> <Icon  name='bus-search' /></div>
              {suggestion.text}
        </div>
      );
    }
  
    const matches = match(suggestion.text, query);
    const parts = parse(suggestion.text, matches);
  
    return (
      <div className={isHighlighted ? `${classes.menuItem} ${classes.selected}` : classes.menuItem}>
        <div>
          {
            parts.map((part, index) => {
              return part.highlight 
                ? <span key={index} className={classes.highlightSpan}>
                    { part.text }
                  </span>
                : <strong key={index} className={classes.span}>
                    { part.text }
                  </strong>
            })
          }
        </div>
      </div>
    );
  };
  

  const renderSuggestionsContainer = options => {
    const { containerProps, children } = options;

    return (
      <div {...containerProps}>
        { children }
      </div>
    );
  }

  const renderInput = inputProps => {
    const { classes, ref, ...other } = inputProps;
    const style = suggestionsDisplayed 
      ? `${classes.input} ${classes.borders}` 
      : classes.input;

    return (
      <input 
        ref={ref}
        className={style}
        {...other}
      />
    )
  }

  const handleCurrentDeparture = (inputValue)=>{
    setCurrentDeparture(inputValue)
  }

  const getSuggestions = async inputValue => {
    const {countryId = 1} =country;
    if(usesElastic && features.enableElasticSearch) {
      const { data } = await axios(`${process.env.STOPS_API_URL}stops/stops/search?term=${inputValue}&idCountry=${countryId}`);
      if (data){
        setSuggestionsDisplayed(true);
        setSuggestions(data.items);
      }
    }

    return hasHeaders
      ? source.map(item => ({
          title: item.title,
          suggestions: item.suggestions
            .filter(suggestion => suggestion.text.toLowerCase().includes(inputValue))
            .slice(0, 6)
        }))
      : source.filter(suggestion => suggestion.text.toLowerCase().includes(inputValue)).slice(0, 10);
  }

  
  const handleSuggestionsFetchRequested = async ({ value }) => {
    const inputValue = removeAccents(value.trim().toLowerCase());
    const inputLength = inputValue.length;
    if(inputLength < characters) return [];
    
    handleCurrentDeparture(inputValue)
  };

  const handleChange = (event, { newValue }) => {
    if (newValue === '') {
      onSelectItem(null);
      setSuggestionsDisplayed(false);
    }

    if (onKeyDown) {
      onKeyDown(newValue);
      setSuggestionsDisplayed(true);
    }

    setSelected(newValue);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 9 && highlightedSuggestion) {
      setSelected(highlightedSuggestion.text);
      onSelectItem(highlightedSuggestion);
    }

    if((event.keyCode === 38 || event.keyCode === 40) && suggestions.length) setSuggestionsDisplayed(true);
  };

  const handleBlur = () => {
    setSuggestionsDisplayed(false);
    
    if (onBlurSelected && highlightedSuggestion) {
      setSelected(highlightedSuggestion.text);
      onSelectItem(highlightedSuggestion);
    }
  };

  const handleClick = event => {
    event.target.select();
  }
  
  const shouldRenderSuggestions = value => characters <= value.length;
  const getSectionSuggestions = section => section.suggestions

  const renderSectionTitle = section => {
    return (
      section.suggestions.length > 0 &&
        <div className={classes.headerNenuItem}>
          { section.title }
        </div>
    );
  }

  return (
    <Autosuggest
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderInputComponent={renderInput}
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      renderSuggestionsContainer={renderSuggestionsContainer}
      highlightFirstSuggestion
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      onSuggestionsClearRequested={() => setSuggestions([])}
      onSuggestionHighlighted={({ suggestion }) => setHighlightedSuggestion(suggestion)}
      inputProps={{
        classes,
        value: selected,
        onChange: handleChange,
        onKeyDown: handleKeyDown,
        onClick: handleClick,
        onBlur: handleBlur,
        ...inputOptions,
        'data-testid': 'input-text'
      }}
      multiSection={hasHeaders}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
    />
  );
}

Autocomplete.defaultProps = {
  characters: 2,
  hasHeaders: false,
  usesElastic: false
}

export default Autocomplete;