import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/button';
import IncrementSelector from 'components/shared/IncrementSelector';
import { SearchLabel } from 'components/shared/searchbox';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

const SearchSelectPassenger = ({ onChange, maxValue, customClasses, values:receivedValues, onClick, showButton ,showGenericMode }) => {
    useStyles(classes);
    const { t } = useTranslation('searchSelectPassenger');
    const [values, setValues] = useState(
        !receivedValues ?
            {
                adults: 1,
                minors: 0,
                infants: 0,
                passengers: 1
            }
            :
            {
                adults: receivedValues.adults,
                minors: receivedValues.minors,
                infants: receivedValues.infants,
                passengers: receivedValues.passengers
            }
    );

    useEffect(() => {
        const total = values.adults + values.minors + values.infants;
        const newVaules = { ...values, passengers: total }
        setValues(newVaules);
        if (onChange) onChange(newVaules);
    }, [values.adults, values.minors, values.infants])

    const handlerChange = (id, value) => setValues({ ...values, [id]: value });

    const isDisabled = values.passengers >= maxValue;

    return (
        <div>
            { showGenericMode ? 
                <div>
                    <div>
                        <SearchLabel 
                            iconName='users' 
                            label={t('persons')} 
                            htmlFor='adults' 
                            customClasses={classes}
                        />
                    </div>
                    <div className={customClasses.middleDiv}>
                        <IncrementSelector 
                            data-testid='adults'
                            className={classes.customHeight} 
                            minValue={1} 
                            value={values.adults} disabled={isDisabled}
                            onChange={value => handlerChange('adults', value)} 
                        />
                    </div>
                </div>
            :
            <div className={`${classes.root} ${customClasses.root}`}>
                <div className={`${classes.selectorContainer} ${customClasses.selectorContainer}`}>
                    <div className={`${classes.selectorLeft} ${customClasses.selector}`}>
                        <div>
                            <SearchLabel label={t('adults')} htmlFor='adults' />
                        </div>
                        <div className={customClasses.middleDiv}>
                            <IncrementSelector 
                                data-testid='adults' 
                                minValue={1} 
                                value={values.adults} disabled={isDisabled}
                                onChange={value => handlerChange('adults', value)} 
                            />
                        </div>
                        <div>
                            <p className={`${classes.text} ${customClasses.text}`}>
                                { t('adultsAge') }
                            </p>
                        </div>
                    </div>

                    <div className={`${classes.selectorCenter} ${customClasses.selector}`}>
                        <div>
                            <SearchLabel label={t('minors')} htmlFor='minors' />
                        </div>
                        <div className={customClasses.middleDiv}>
                            <IncrementSelector 
                                data-testid='minors' 
                                disabled={isDisabled} 
                                value={values.minors}
                                onChange={value => handlerChange('minors', value)} 
                            />
                        </div>
                        <div>
                            <p className={`${classes.text} ${customClasses.text}`}>
                                { t('minorsAge') }
                            </p>
                        </div>
                    </div>

                    <div className={`${classes.selectorRigth} ${customClasses.selector}`}>
                        <div>
                            <SearchLabel label={t('infants')} htmlFor='infants' />
                        </div>
                        <div className={customClasses.middleDiv}>
                            <IncrementSelector 
                                data-testid='infants' 
                                disabled={isDisabled} 
                                value={values.infants}
                                onChange={value => handlerChange('infants', value)} 
                            />
                        </div>
                        <div>
                            <p className={`${classes.text} ${customClasses.text}`}>
                                { t('infantsAge') }
                            </p>
                        </div>
                    </div>
    
                    {
                        showButton &&
                            <div>
                                <Button onClick={onClick}>
                                    { t('accept') }
                                </Button>
                            </div>
                    }
                </div>
            </div>
            }
        </div>
    )
}

SearchSelectPassenger.defaultProps = {
    maxValue: 1,
    onChange: () => { },
    customClasses: {
        root: '',
        middleDiv: '',
        selectorContainer: '',
        selector: '',
        text: ''
    }
}

export default SearchSelectPassenger;