import React from 'react'

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ onSelectItem, id, placeholder, ...props }) => {
    useStyles(classes);
    const handleChange = event => onSelectItem(event.target.value, id);

    return (
        <textarea
            className={classes.input}
            onBlur={handleChange}
            placeholder={placeholder}
            {...props}
        />
    )
}