import React from 'react';

import Button from 'components/shared/button';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default ({ children, ...rest }) => {
    useStyles(classes, globalStyles);
    
    return (
        <div className={globalStyles.flexAlignCenter}>
            <Button {...rest} customClasses={classes.button}> 
                { children } 
            </Button>
        </div>
    )

}