import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components
import Icon from 'components/shared/icon';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

const IncrementSelector = ({ minValue, maxValue, value:receivedValue, onChange, disabled, className, onMinusClick, onPlusClick, ...rest }) => {
    useStyles(classes);
    const [ value, setValue ] = useState(receivedValue);    

    const handlerClickMinus = () => {
        if(value > minValue) {
            const newValue = value - 1;
            setValue(newValue);
            onMinusClick  ? onMinusClick(newValue) : onChange(newValue);
        }
    }

    const handlerClickPlus = () => {
        if(!maxValue || value < maxValue) {
            const newValue = value + 1;
            setValue(newValue);
            onPlusClick ? onPlusClick(newValue) : onChange(newValue);
        }
    }

    return (
        <div {...rest} className={`${classes.root} ${className}`}>
            <button 
                data-testid='left' 
                aria-label= 'plus button'
                className={classes.button} 
                onClick={handlerClickMinus} 
                disabled={value <= minValue}
                type='button'
            >
                <Icon classes={classes.icon} name={'minus-solid'} />
            </button>
            <div className={classes.inputConteiner}>
                <input
                    data-testid='input' 
                    className={classes.input} 
                    disabled 
                    value={value} 
                />
            </div>
            <button 
                data-testid='right'
                aria-label = 'minus button'
                className={classes.button} 
                onClick={handlerClickPlus} 
                disabled={disabled}
                type='button'
            >
                <Icon classes={classes.icon} name={'plus-solid'} />
            </button>
        </div>
    )
}

IncrementSelector.defaultProps = {
    minValue: 0,
    value: 0,
    onChange: () => {},
    disabled: false
}

IncrementSelector.prototype = {
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}

export default IncrementSelector;