import React from 'react'

// Components
import DatePicker from 'components/shared/datepicker';
import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import moment from 'moment';

export default ({ onSelectItem, minDate, initValue, id, err , ...rest }) => {
    const handlerSelectedItem = item => onSelectItem(item, id);
    useStyles(classes);

    return (
        <>
        <DatePicker 
            {...rest} 
            onSelectItem={handlerSelectedItem} 
            minDate={minDate ?? moment()} 
            initValue={initValue} 
        />
        {
             err && err.status &&
             <div className={classes.helperText}>
                 { err.message }
             </div>
        }
        </>
    )
}
