// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7a7a9c6d4c807ba9999f5f6bfb47f61e-scss{display:grid;text-transform:uppercase}._028d43f3103aa86a6f4fd3d7db78feda-scss{text-align:center;grid-gap:35px}.facb16a07ce1909854a8b39ff42d557b-scss{justify-self:left}._626588971e178907d051fff9a83216fe-scss{justify-self:center}._45bb921078616d0b91b1565aa2387b44-scss{justify-self:right}._484efa04b0c5b0881533221e008df717-scss{color:var(--white-light);margin-top:3px;font-size:14px}.c59b4c37d858516029e638c8836eebad-scss{height:37px !important}.a61ff0191edceafce592d920d01a06ee-scss{width:16px !important}\n", ""]);
// Exports
exports.locals = {
	"root": "_7a7a9c6d4c807ba9999f5f6bfb47f61e-scss",
	"selectorContainer": "_028d43f3103aa86a6f4fd3d7db78feda-scss",
	"selectorLeft": "facb16a07ce1909854a8b39ff42d557b-scss",
	"selectorCenter": "_626588971e178907d051fff9a83216fe-scss",
	"selectorRigth": "_45bb921078616d0b91b1565aa2387b44-scss",
	"text": "_484efa04b0c5b0881533221e008df717-scss",
	"customHeight": "c59b4c37d858516029e638c8836eebad-scss",
	"icon": "a61ff0191edceafce592d920d01a06ee-scss"
};
module.exports = exports;
