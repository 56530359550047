// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".e62b4f45bde2e566a08c7465d2463029-scss{color:var(--white-light);background:#900;padding:10px 5px;margin:0px 3px;font-size:10px;text-transform:uppercase;font-weight:900;line-height:1.25;margin-top:0;border-radius:6px;border-top-left-radius:0;border-top-right-radius:0}\n", ""]);
// Exports
exports.locals = {
	"helperText": "e62b4f45bde2e566a08c7465d2463029-scss"
};
module.exports = exports;
