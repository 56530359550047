import React from 'react'

import { SearchSelectPassenger } from 'components/shared/searchbox';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ adults, minors, infants, passengers, handlerPassengersChange, handleSearch ,showGenericMode }) => {
    useStyles(classes);
    return (
        <div className={classes.inputsSelectorPassenger}>
            <SearchSelectPassenger 
                values={{ adults, minors, infants, passengers }} 
                maxValue={6} 
                onChange={handlerPassengersChange} 
                onClick={handleSearch} 
                showGenericMode={showGenericMode}
                customClasses={
                    { 
                        root: classes.selectorPassengerContainer, 
                        middleDiv: classes.middleDiv, 
                        selectorContainer: classes.selectorContainer, 
                        selector: showGenericMode?classes.selectorGenericMode: classes.selector, 
                        text: classes.text
                    }
                } 
                showButton
            />
        </div>
    )
}
