import React, { useState, useEffect, useMemo } from 'react';
import { SearchInput } from 'components/shared/searchbox';
import useGoogleMaps from 'hooks/googleMaps';
import { mapData, mapItem } from './mapper';

export default ({ placeholder, id, onSelectItem, validateTrip, initValue }) => {
    const [inputValue, setInputValue] = useState({ text: '' });
    const [options, setOptions] = useState([]);
    const [modo, setModo] = useState(['establishment', 'geocode']);
    const { autocompleteService, geocoder } = useGoogleMaps();
    const restrictionCountry = 'ar';
    var initialTime;
    var look = false;
    var last = "";
    var search = "";
    var listing = false;

    const fetch = useMemo(() => async (request, callback) => {
        if (!autocompleteService || look || request.input.text == last) return;
        look = true;
        last = request.input.text;
        initialTime = undefined;
        await autocompleteService.current.getPlacePredictions({
            input: request.input.text,
            types: modo,
            componentRestrictions: { country: restrictionCountry },
            language: "es"
        }, callback);
    }, [inputValue.text]);

    useEffect(() => {
        const callback = data => {
            setModo((!data) ? ['adress'] : ['establishment', 'geocode']);

            const mappedData = mapData(data);
            setOptions(mappedData);
            listing = true;
        }
        fetch({ input: inputValue }, callback);
    }, [inputValue.text]);

    const Now = () => {
        var d = new Date();
        return d.getTime();
    }

    const sendFound = () => {
        var rightnow = Now();
        if (look == false && initialTime != undefined && rightnow - initialTime > 1500 && search != undefined) {
            const input = { text: search };
            if (!listing)
                setInputValue(input);
            initialTime = undefined;
            look = true;
        }
    }

    const timer = () => setInterval(sendFound, 2000);

    timer();

    const handleChange = value => {
        const input = { text: value };
        //me fijo que tecla presiono.
        var keypressed = value.substring(value.length - 1, value.length);
        // guardo en la variable search el valor buscado
        search = value;
        // inicializo la variable de de tiempo para que se active al segundo y medio
        initialTime = Now();
        if (keypressed === " " || keypressed === ",") {
            if (value != last && last.length < value.length) {
                look = false;
            }
            // si el valor es distinto al ultimo ingresado puede realizar una nueva busqueda en la api
            setInputValue(input);
            look = true;
        }
    };

    const handlerSelectedItem = async item => {
        if (look) initialTime = Now();
        if (!item || !geocoder || look) return;
        await geocoder.geocode({ 'address': item.text }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                const mappedItem = mapItem(results[0], item);
                // guardo como last el valor seleccionado para que no busque de nuevo
                last = mappedItem.text;
                // lo igualo a search por la misma razon
                search = last;
                look = true;
                initialTime = Now();
                listing = false;
                onSelectItem(mappedItem, id);
            }
        });
    }

    return (
        <SearchInput
            field={id}
            id={id}
            placeholder={placeholder}
            onSelectItem={handlerSelectedItem}
            stops={options}
            initValue={initValue}
            onKeyDown={handleChange}
            err={validateTrip}
            disableUnderline
        />
    )
}