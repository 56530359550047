import { useState, useEffect } from 'react';

export default () => {
    const [autocompleteService, setAutocompleteService] = useState({ current: null });
    const [geocoder, setGeocoder] = useState();

    useEffect(() => {
        if (!autocompleteService.current && window.google) {
           setAutocompleteService({ current: new window.google.maps.places.AutocompleteService() });
           setGeocoder(new window.google.maps.Geocoder())
        }
     }, [])

     return {
        autocompleteService,
        geocoder
     }
}