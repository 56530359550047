import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

import { useApplication } from 'hooks/application';

import breakpoints from 'shared/breakpoints';

import moment from 'moment';

export default ({ onSelectItem, initValue, minDate, placeholder }) => {
  const { culture:{ formatDate, iso_code, code } } = useApplication();
  const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.sm})`});

  const [selectedDate, setSelectedDate] = useState(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const LocaleEs = async () => {
      const locales = await import('moment/locale/es');
      return locales;
    }

    const LocalePt = async () => {
      const locales = await import('moment/locale/pt');
      return locales;
    }

    const localeMap = {
      'es-AR': LocaleEs,
      'es-PY': LocaleEs,
      'es-PE': LocaleEs,
      'es-MX': LocaleEs,
      'en-US': () => 'en',
      'pt-BR': LocalePt,
    };

    const getLocale = async () => {
      const locale = await localeMap[iso_code]();
      moment.locale(code, locale);
    }

    getLocale();
  }, [code]);

  useEffect(() => {
    const startDate = initValue ? moment(initValue) : null;

    if (startDate && startDate.isBefore(minDate, 'day')) {
      setSelectedDate(moment(minDate));
    } else {
      setSelectedDate(startDate);
    }
  }, [initValue, minDate]);

  const handleChange = date => {
    onSelectItem(date);
    setSelectedDate(date);
  };

  return (
      <div>
        <SingleDatePicker
          date={selectedDate}
          onDateChange={handleChange}
          focused={focused}
          placeholder={placeholder}
          onFocusChange={({ focused }) => setFocused(focused)}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          displayFormat={formatDate}
          isOutsideRange={day => day.isBefore(minDate, 'day')}
          readOnly
          showClearDate
          noBorder
          block
        />
      </div>
  );
}