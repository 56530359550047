import { message } from "antd";

const errorCompare = (trip, message) => {
    const { departure, destination } = trip;

    return {
        message,
        status: departure && destination && departure.id === destination.id ? true : false
    };
}

const emptyDate = (departureDate , message)=>{
    return {
        message,
        status: departureDate==null? true : false
    };

}


export {
    errorCompare,
    emptyDate
}