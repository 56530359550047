import moment from 'moment';

const validateForm = trip => {
    const { departure, destination, departureDate, returnDate } = trip;

    if (!departure || !destination || !departureDate) {
        return false;
    }

    if (departure.id === destination.id) {
        return false;
    }

    if (returnDate && (returnDate < departureDate)) {
        return false;
    }

    return true;
}

const isMexicoOrUSA = item => {
    const mxId = 167;
    const usId = 188;

    return item.countryId  === mxId || item.countryId === usId;
}

const generateUrl = (trip, servicesText, seoText, searchQueryString = '') => {
    const { departure, destination, departureDate, returnDate, passengers, adults, minors, infants } = trip;
    const people = passengers && `/${adults}/${minors}/${infants}`;

    return `/${servicesText}/${seoText}/` +
        `${departure.nameSeo}/` +
        `${destination.nameSeo}/` +
        `${departure.id}/` +
        `${destination.id}/` +
        `${moment(departureDate).format('DD-MM-YYYY')}/` +
        `${returnDate ? moment(returnDate).format('DD-MM-YYYY') : '_'}` +
        `${people ? people : ''}` +
        searchQueryString;
}

export { validateForm, generateUrl, isMexicoOrUSA }