// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".c53b97c5873cc5c06140d7e2d1d34dbb-scss{position:relative;grid-column:1 / 3}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._2fcfbb85ea610664192e7a10a30d5a0d-scss{background:var(--white-light);display:flex;position:absolute;z-index:1;align-items:center;flex-direction:column;box-shadow:rgba(0,0,0,0.25) 0 2px 4px;border-radius:10px}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._2fcfbb85ea610664192e7a10a30d5a0d-scss:before{content:'';background:var(--white-light);position:absolute;height:16px;width:16px;left:16px;transform:rotateZ(45deg);top:-7px;border:1px solid #ccc;box-shadow:0 0 4px rgba(0,0,0,0.25)}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._2fcfbb85ea610664192e7a10a30d5a0d-scss>div label{color:var(--black-dark)}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._2fcfbb85ea610664192e7a10a30d5a0d-scss>div p{color:var(--black-dark);font-size:12px}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._4e6672146cb13b162c975ed661e001c3-scss{background:var(--white-light);padding:10px 20px;width:100%;z-index:2;border-radius:10px}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._4e6672146cb13b162c975ed661e001c3-scss>div{padding:10px 0}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._9eab9d8c3984ed8cb4854cd5169e92f6-scss{display:grid;grid-template-rows:repeat(2, 1fr);grid-template-columns:repeat(2, 1fr)}.c53b97c5873cc5c06140d7e2d1d34dbb-scss .c7add118be5486a4484e4a9fdb89b032-scss{display:grid;grid-template-rows:repeat(2, 1fr);grid-template-columns:repeat(0, 1fr)}.c53b97c5873cc5c06140d7e2d1d34dbb-scss ._7c6058079d257c39197a7524bf7dbae4-scss{display:flex;grid-row:span 2;align-items:center}.c53b97c5873cc5c06140d7e2d1d34dbb-scss .acd123ebf1916151a5e5b6c9cb781590-scss{font-size:12px}\n", ""]);
// Exports
exports.locals = {
	"inputsSelectorPassenger": "c53b97c5873cc5c06140d7e2d1d34dbb-scss",
	"selectorPassengerContainer": "_2fcfbb85ea610664192e7a10a30d5a0d-scss",
	"selectorContainer": "_4e6672146cb13b162c975ed661e001c3-scss",
	"selector": "_9eab9d8c3984ed8cb4854cd5169e92f6-scss",
	"selectorGenericMode": "c7add118be5486a4484e4a9fdb89b032-scss",
	"middleDiv": "_7c6058079d257c39197a7524bf7dbae4-scss",
	"text": "acd123ebf1916151a5e5b6c9cb781590-scss"
};
module.exports = exports;
