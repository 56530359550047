// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._57efe533f96f94df787bbcb6ae43498c-scss{position:relative}._57efe533f96f94df787bbcb6ae43498c-scss ._2a96df96e176951e4977a432794cb653-scss{top:0;left:0;right:0;bottom:0;display:flex;z-index:2;position:fixed;align-items:center;touch-action:none;justify-content:center;background-color:rgba(0,0,0,0.5)}._57efe533f96f94df787bbcb6ae43498c-scss .cf064e44213376de0b464a085f4a3b8f-scss{width:100%;background:var(--white-light);padding:10px;font-size:15px;border-style:none;border-radius:6px;cursor:pointer}._57efe533f96f94df787bbcb6ae43498c-scss ._2bee0c91feeb5b91f1e4eb2e0ddb76e6-scss{cursor:pointer;background:var(--primary-main);color:var(--white-light);text-align:center;padding:10px 0px}._57efe533f96f94df787bbcb6ae43498c-scss ._0d6298c1fb322f8049baab1ca9637068-scss{cursor:pointer;position:absolute;display:flex;top:0;right:5px;bottom:0;align-items:center}._57efe533f96f94df787bbcb6ae43498c-scss .c0271c638dac2d2c23850f85163aa475-scss{width:20px;fill:var(--primary-main)}\n", ""]);
// Exports
exports.locals = {
	"container": "_57efe533f96f94df787bbcb6ae43498c-scss",
	"root": "_2a96df96e176951e4977a432794cb653-scss",
	"input": "cf064e44213376de0b464a085f4a3b8f-scss",
	"cancelButton": "_2bee0c91feeb5b91f1e4eb2e0ddb76e6-scss",
	"iconContainer": "_0d6298c1fb322f8049baab1ca9637068-scss",
	"icon": "c0271c638dac2d2c23850f85163aa475-scss"
};
module.exports = exports;
