// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._255cde918775aef00143998e0b392a9f-scss{background-color:var(--searchBox-button-bg-color) !important;color:var(--searchBox-button-font-color) !important;width:100%;height:45px;font-size:18px !important}._255cde918775aef00143998e0b392a9f-scss:hover{background-color:var(--searchBox-button-bg-color-hover) !important;color:var(--searchBox-button-font-color-hover) !important}._255cde918775aef00143998e0b392a9f-scss:disabled{opacity:0.8}\n", ""]);
// Exports
exports.locals = {
	"button": "_255cde918775aef00143998e0b392a9f-scss"
};
module.exports = exports;
